import "./Maps.js";
import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Locations", {
    element: '.f-locations',
    GoogleMapId: 'm-google-map',
    mapZoomLevel: 5,
    MarkerIcon: {
        url: "/-/media/Project/Corporate/images/marker.png"
    },
    templates: {
        columns: "<div class='columns/>",
        column: "<div class='column'/>"
    },
    key: "AIzaSyDgh0W-G0BgraSU1mBOEU7Z7zN2yhxpGTw",
    mapStyles: [{
        'featureType': 'administrative',
        'elementType': 'labels.text.fill',
        'stylers': [{
            'color': '#929494'
        }]
    }, {
        'featureType': 'landscape',
        'elementType': 'all',
        'stylers': [{
            'color': '#929494'
        }]
    }, {
        'featureType': 'landscape',
        'elementType': 'geometry.fill',
        'stylers': [{
            'color': '#f2f2f2'
        }]
    }, {
        'featureType': 'poi',
        'elementType': 'all',
        'stylers': [{
            'visibility': 'off'
        }]
    }, {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [{
            'color': '#ebebeb'
        }]
    }, {
        'featureType': 'road',
        'elementType': 'all',
        'stylers': [{
            'saturation': -100
        }, {
            'lightness': 45
        }]
    }, {
        'featureType': 'road',
        'elementType': 'geometry.fill',
        'stylers': [{
            'color': '#ebebeb'
        }]
    }, {
        'featureType': 'road',
        'elementType': 'labels.text.fill',
        'stylers': [{
            'color': '#929494'
        }]
    }, {
        'featureType': 'road.highway',
        'elementType': 'all',
        'stylers': [{
            'visibility': 'simplified'
        }]
    }, {
        'featureType': 'road.arterial',
        'elementType': 'labels.icon',
        'stylers': [{
            'visibility': 'off'
        }]
    }, {
        'featureType': 'transit',
        'elementType': 'all',
        'stylers': [{
            'visibility': 'off'
        }]
    }, {
        'featureType': 'water',
        'elementType': 'all',
        'stylers': [{
            'color': '#333'
        }, {
            'visibility': 'on'
        }]
    }, {
        'featureType': 'water',
        'elementType': 'geometry.fill',
        'stylers': [{
            'color': '#a4aeb8'
        }]
    }],
    markers: "",
    infowindows: "",
    loadScript: function (url, callback) {
        var script = document.createElement("script")
        script.type = "text/javascript";
        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback("Success");
                }
            };
        } else {
            script.onload = function () {
                callback("Success");
            };
        }
        script.onerror = function () {
            callback("Error")
        }
        script.src = url;
        document.body.appendChild(script);
    },
    loadMapsScript: function (callback) {
        var self = this;
        if ($("script[src=\"https://maps.googleapis.com/maps/api/js?key=" + self.key + "&libraries=&v=weekly\"]").length == 0) {
            self.loadScript("https://maps.googleapis.com/maps/api/js?key=" + self.key + "&libraries=&v=weekly", callback);
        } else {
            callback("Success");
        }
    },
    getCountries: function (regions) {
        var countries = [];
        for (var i in regions) {
            if (countries.indexOf(regions[i].Name == -1)) {
                if (regions[i].Locations.length > 0) { /// Make country available if and only if the locations are available
                    countries.push(regions[i].Name);
                }
            }
        }
        return countries;
    },
    makeCountryDropdown: function (countries, callback) {
        var self = this;
        var col = $(self.templates.column);
        var wrapper = document.createElement("div");
        var title = $("<h2 class=\"a-title\">Country</h2>");
        var dd = document.createElement('select');
        wrapper.classList.add("a-dropdown");
        for (var index = 0; index < countries.length; index++) {
            var op = document.createElement('option');
            op.value = countries[index];
            op.innerText = countries[index];
            dd.appendChild(op);
        }
        wrapper.appendChild(dd)
        col.addClass('is-full');
        $(col).append(title);
        col.append(wrapper);
        callback(col);
    },
    getTabs: function (regions, country) {
        var tabs = [];
        for (var i = 0; i < regions.length; i++) {
            var region = regions[i];
            if (region.Name.toLowerCase() == country.toLowerCase()) {
                for (var j = 0; j < region.Locations.length; j++) {
                    var l = region.Locations[j];
                    if (tabs.indexOf(l.Type) == -1) {
                        tabs.push(l.Type);
                    }
                }
            }
        }
        return tabs;
    },
    makeTabsForLocations: function (tabs) {
        var self = this;
        var col = $("<div class='column is-full'/>");
        var tabContainer = $('<div class="columns"><div class="column is-full"><ul></ul></div></div>');
        for (var i = 0; i < tabs.length; i++) {
            var li = $("<li data-key='" + tabs[i] + "' class='address-type " + (i == 0 ? "is-active" : "") + "'><span>" + tabs[i] + "</span></li>");
            $(tabContainer).find('ul').append(li);
        }
        col.append(tabContainer);
        return col;
    },
    placeAddressesInTable: function (regions, country) {
        // var self = this;
        var countryFields = regions.filter(function (x) {
            return x.Name.toLowerCase() == country.toLowerCase();
        })[0];
        var str = "<ul>";
        for (var i = 0; i < countryFields.Locations.length; i++) {
            var type = countryFields.Locations[i]["Type"];
            if ($(self.element).find(".address-type").length == 0) {
                var lat = countryFields.Locations[i]["Coordinates"]["Lat"];
                var long = countryFields.Locations[i]["Coordinates"]["Long"];
                var entity = countryFields.Locations[i]["Entity"];
                var city = countryFields.Locations[i]["City"];
                var state = countryFields.Locations[i]["State"];
                var address = countryFields.Locations[i]["Address"];
                var phone = countryFields.Locations[i]["Phone"];
                var zip = countryFields.Locations[i]["Zip"];
                str += "<li>";
                str += "<a class=\"anchor\" href=\"#m-google-map\">";
                str += "<div class='address-list' data-type='" + type + "' data-lat='" + lat + "' data-long='" + long + "' data-title='" + entity + "' data-city='" + city + "' data-state='" + state + "' data-address='" + address + "' data-phone='" + phone + "' data-zip='" + zip + "'>";
                str += "<h2 class='a-title'>" + entity + "</h2>";
                str += "<span class='a-description'>" + city + ", " + state + "</span>";
                str += "</div>";
                str += "</a>";
                str += "</li>";
            }
        }
        str += "</ul>"
        return str;
    },
    initMap: function (mapWrapper, regions, country) {
        var self = this;
        $(mapWrapper).attr("id", self.GoogleMapId);
        self.initMarker();
    },
    createMap: function (regions, country, el) {
        var self = this;
        var col = $(self.templates.column);
        col.addClass("is-full");
        el.append(col);
        var cols = $("<div class='columns'/>");
        col.append(cols);
        var addressTable = $(self.templates.column);
        var mapSection = $(self.templates.column);
        cols.append(addressTable);
        cols.append(mapSection);
        addressTable.addClass("is-4");
        mapSection.addClass("is-8 m-google-map");
        self.loadMapsScript(function (status) {
            if (status == "Success") {
                self.initMap(mapSection, regions, country);
            } else {
                $(self.el).remove();
            }
        });
        addressTable.append(self.placeAddressesInTable(regions, country));
    },
    recreateTabs: function (country, regions, el) {
        var self = this;
        $(el).find(">.column:nth-child(2)").remove();
        var tabData = self.getTabs(regions, country);
        var tabs = self.makeTabsForLocations(tabData);
        $(el).append(tabs);
        $(tabs).find("li").on('click', function (e) {
            $(this).parent().find('.is-active').removeClass('is-active');
            $(this).addClass("is-active");
            $(this).closest(".column").next().remove();
            self.createMap(regions, country, $(el).find(">.column:nth-child(2)>.columns"));
            self.showActiveData();
            self.initMarker();
            e.preventDefault()
            return false;
        });
        self.createMap(regions, country, $(el).find(">.column:nth-child(2)>.columns"));
        self.showActiveData();
        self.initMarker();
    },
    init: function () {
        var self = this;
        $(self.element).each(function () {
            var el = $(this);
            var regions = $(this).data("regions");
            var countries = self.getCountries(regions);
            self.makeCountryDropdown(countries, function (dropdown) {
                $(el).append(dropdown);
                $(el).find('.a-dropdown select').on('change', function () {
                    self.recreateTabs($(this).val(), regions, el);
                    self.showActiveData();
                    self.initMarker();
                });
                self.recreateTabs($(el).find('.a-dropdown select').val(), regions, el);
                self.showActiveData();
            });
        });
    },
    initMarker: function () {
        var self = this;
        if ($(self.element).find('.address-list.active').length > 0 && !!window.google) {
            var lat = $($(self.element).find('.address-list.active')[0]).data("lat");
            var lng = $($(self.element).find('.address-list.active')[0]).data("long");

            var position = new google.maps.LatLng(lat, lng);
            var mapOptions = {
                zoom: self.mapZoomLevel,
                center: position,
                zoomControl: false,
                disableDoubleClickZoom: true,
                mapTypeControl: false,
                scaleControl: false,
                scrollwheel: true,
                panControl: true,
                streetViewControl: false,
                draggable: true,
                overviewMapControl: true,
                overviewMapControlOptions: {
                    opened: false,
                },
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                styles: self.mapStyles
            };
            var map = new google.maps.Map(document.getElementById(self.GoogleMapId), mapOptions);
            self.initMapMarkers(map);
        }
    },
    initMapMarkers: function (map) {
        var self = this;
        self.markers = [];
        self.infowindows = [];
        var locations = $(self.element).find('.address-list');
        var zoomLevel = locations.length > 1 ? 6 : 10;
        locations.each(function (i) {
            var activeTab = $(self.element).find(".address-type.is-active").data("key");
            if ($(this).data("type") == activeTab) {
                var lat = $(this).data("lat");
                var lng = $(this).data("long");
                var position = new google.maps.LatLng(lat, lng);
                var title = $(this).data("title");
                var address = $(this).data("address");
                var city = $(this).data("city");
                var state = $(this).data("state");
                var zip = $(this).data("zip");
                var phone = $(this).data("phone");
                var infoMarkup = '<div class="map-infowindow">' +
                    '<div class="image-wrapper">' +
                    '</div>' +
                    '<div class="address">' +
                    ('<h4>' + title + '</h4>') +
                    ('<h6>' + address + '</h6>') +
                    ('<h6>' + city + ', ' + state + ', ' + zip + '</h6> ') +
                    ('<h6>Phone: ' + phone + '</h6>') +
                    ('<a href="http://www.google.com/maps/place/' + lat + ',' + lng + '" target="_blank">View in Google Maps</a>') +
                    '</div>' +
                    '</div>';

                var infowindow = new google.maps.InfoWindow({
                    content: infoMarkup
                });

                var marker = new google.maps.Marker({
                    icon: self.MarkerIcon,
                    position: position,
                    map: map,
                });

                marker.addListener('click', function () {
                    self.closeMarkers();
                    self.markers.forEach(function (mar) {
                        mar.setOpacity(.5);
                    });
                    this.setOpacity(1);
                    console.log(marker.position.lat(), marker.position.lng())
                    infowindow.setContent(infoMarkup);
                    infowindow.open(map, marker);
                    map.setCenter(marker.getPosition());
                    map.setZoom(zoomLevel);
                });
                $(this).on('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    self.closeMarkers();
                    self.markers.forEach(function (mar) {
                        mar.setOpacity(.5);
                    });
                    marker.setOpacity(1);
                    map.setZoom(zoomLevel);
                    infowindow.open(map, marker);
                    map.setCenter(marker.getPosition());

                    var anchor = $(this).parent().get(0);
                    $('html, body').animate({
                        scrollTop: $(anchor.hash).offset().top - ($('.f-header').height() || 0)
                    }, 800, function () {
                        $(anchor.hash).focus();
                    });

                    return false;
                });
                google.maps.event.addListener(infowindow, 'closeclick', function () {
                    self.markers.forEach(function (mar) {
                        mar.setOpacity(1);
                    });
                });

                self.markers.push(marker);
                self.infowindows.push(infowindow);
            }
        });
    },
    closeMarkers: function () {
        var self = this;
        self.markers.forEach(function (mar) {
            mar.setOpacity(1);
        });
        $(self.infowindows).each(function () {
            var infowindow = $(this)[0];
            if (infowindow) {
                infowindow.close();
            }
        });
    },
    showActiveData: function () {
        var self = this;
        var activeTab = $(self.element).find(".address-type.is-active").data("key");
        $(self.element).find(".address-list").removeClass("active");
        $(self.element).find(".address-list").each(function () {
            if ($(this).data("type") === activeTab) {
                $(this).addClass("active");
            }
        });
    }
});