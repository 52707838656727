import { gsap } from 'gsap/';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Component } from "fq-foundation/Scripts/Component";
// scrollTo
new Component().create("CapabilitiesExperience", {
    element: '#fq-capabilities-experience',
    globals: {
        capabilitiesSlides: $('#fq-capabilities-slides'),
        rotaryNavigation: $('#fq-rotary-navigation'),
        rotaryItemsClone: null,
        introSlide: $('#fq-intro-slide'),
        rotaryRotation: 0,
        backgroundTrackInitiated: false,
        backgroundMP3: null,
        voiceoverMP3: null
    },
    init: function () {
        var self = this;
        self.bindEvents();
    },
    bindEvents: function () {
        var self = this;
        self.slideSequence();
        self.handleWindowResize();
        self.showDescription();
        self.handleRotaryItemClick();
        self.revealRotary();
        self.revealNextCapability();
        self.playMp3();
    },
    slideSequence: function () {
        var self = this;
        const $intro = self.globals.introSlide,
            $rotaryNavigation = self.globals.rotaryNavigation;
        // TweenPlugin.activate("scrollTo");
        gsap.registerPlugin(ScrollToPlugin);
        // Fade out Intro slide and reveal rotary navigation
        gsap.to($intro, .5, {
            css: {
                opacity: 1
            },
            onComplete: function () {

                gsap.to($intro, .5, {
                    delay: .5,
                    css: {
                        opacity: 0,
                        'display': 'none'
                    },
                    onComplete: function () {

                        gsap.to($rotaryNavigation, .5, {
                            delay: .5,
                            css: {
                                opacity: 1,
                                'display': 'block'
                            },
                            onComplete: function () {
                                self.distributeFields();
                            }
                        });
                    }
                });
            }
        });
    },
    showDescription: function () {
        var self = this;
        const $descriptions = $('#fq-rotary-items-content-container .fq-rotary-description'),
            $rotaryNavigation = self.globals.rotaryNavigation,
            $categories = $rotaryNavigation.find('.fq-rotary-item');

        if ($categories.length > 0) {

            $categories
                .on('mouseleave', function () {
                    $rotaryNavigation.removeClass('fq-remove-background');
                    $descriptions.removeClass('fq-active');
                })
                .on('mouseover', function () {
                    let targetID = $(this).data('id');
                    $rotaryNavigation.addClass('fq-remove-background');

                    // Filter specific target by data attribute "id" and set active class
                    $descriptions
                        .removeClass('fq-active')
                        .filter(function () {
                            let $scope = $(this);
                            if ($scope.data('id') === targetID) {
                                return $scope;
                            }
                        })
                        .addClass('fq-active');
                });
        }
    },
    handleWindowResize: function () {
        var self = this;
        let resizeTimer;
        // Reposition items on window resize
        $(window).resize(function () {
            // Prevent performance issues: call function once window resize event is completed
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                self.distributeFields();
            }, 250);
        });
    },
    handleRotaryItemClick: function () {
        var self = this;
        const $rotaryItems = self.globals.rotaryNavigation;
        if ($rotaryItems.length > 0) {
            $rotaryItems.on('click', '.fq-rotary-item', function (e) {
                const $target = $(e.currentTarget);
                const $slidesContainer = self.globals.capabilitiesSlides;
                const $slides = $slidesContainer.find('.fq-capabilities-background');
                if ($slides.length > 0) {
                    self.revealSlide($target);
                }
            });
        }
    },
    revealSlide: function (e) {
        var self = this;
        const $target = e.data('id'),
            $slidesContainer = self.globals.capabilitiesSlides,
            $slides = $slidesContainer.find('.fq-capabilities-background'),
            $experienceContainer = $(self.element);

        if ($slides.length > 0) {
            gsap.to($slidesContainer, .5, {
                css: {
                    'opacity': 0,
                    'display': 'block'
                },
                onComplete: function () {
                    gsap.to($slidesContainer, .5, {
                        delay: .5,
                        css: {
                            opacity: 1
                        }
                    });
                }
            });
            $slides
                .removeClass('fq-reveal-slide')
                .filter(function () {
                    let $scope = $(this);
                    if ($scope.data('id') === $target) {
                        return $scope;
                    }
                })
                .addClass('fq-reveal-slide fq-reveal-slide-active');
            self.playVideo();
        }
        $experienceContainer.addClass('fq-slides-opened');
    },
    revealNextCapability: function () {
        var self = this;
        const $slidesContainer = self.globals.capabilitiesSlides;

        if ($slidesContainer.length > 0) {
            // $slidesContainer.on('click', '.fq-next-capability', revealNextHandler);
            $slidesContainer.on('click', '.fq-next-capability, .fq-previous-capability', function (e) {
                const $slidesContainer = self.globals.capabilitiesSlides,
                    $slides = $slidesContainer.find('.fq-capabilities-background'),
                    $clickScope = $(e.target);

                // Destroy voiceover track
                if (self.globals.voiceoverMP3 !== null) {
                    self.globals.voiceoverMP3.unload();
                }

                if (self.globals.backgroundMP3 !== null) {
                    self.globals.backgroundMP3.unload();
                }

                let $filteredSlide = $slides.filter(function (index) {
                    let $slideScope = $(this);
                    if ($slideScope.hasClass('fq-reveal-slide') === true) {
                        return true;
                    }
                });

                // Fade out Slide container
                gsap.to($slidesContainer, .5, {
                    opacity: 0,
                    onComplete: function () {
                        // Use to keep disired slide in scope
                        let $targetSlide;

                        // Remove Reveal class
                        $filteredSlide
                            .removeClass('fq-reveal-slide')
                            .removeClass('fq-reveal-slide-active');

                        // Determine if next/previous
                        // console.log($clickScope.hasClass('fq-previous-capability'))
                        if ($clickScope.hasClass('fq-previous-capability')) {

                            if ($filteredSlide.index() === 0) {
                                $targetSlide = $slides.eq($slides.length - 1);
                            } else {
                                $targetSlide = $filteredSlide.prev();
                            }
                        } else {
                            if ($filteredSlide.index() === $slides.length - 1) {
                                $targetSlide = $slides.eq(0);
                            } else {
                                $targetSlide = $filteredSlide.next();
                            }
                        }
                        // Audio icon toggling
                        $filteredSlide.find('.fq-background-music').removeClass('active');
                        $targetSlide.addClass('fq-reveal-slide');

                        // Slide into view
                        gsap.to($slidesContainer, .5, {
                            opacity: 1,
                            onComplete: function () {
                                $targetSlide.addClass('fq-reveal-slide-active');
                                self.playVideo();

                                // Scroll back to top to ensure content is visible to user
                                gsap.to(window, .3, {
                                    scrollTo: 0
                                });
                            }
                        });
                    }
                });
            });
        }
    },
    revealNextHandler: function (e) {

    },
    playVideo: function () {
        var self = this;
        const $targetVideoSlide = self.globals.capabilitiesSlides.find('.fq-reveal-slide video');
        if ($targetVideoSlide.length > 0) {
            videojs($targetVideoSlide.get(0), {
                'loop': true
            }, function () {
                var myPlayer = self;
                myPlayer.play();
            });
        }
    },
    playMp3: function () {
        var self = this;
        const $slidesContainer = self.globals.capabilitiesSlides;

        if ($slidesContainer.length > 0) {
            $slidesContainer.on('click', '.fq-background-music', function () {
                const $scope = $(this),
                    backgroundTrackURL = $scope.data('background-music'),
                    voiceoverTrackURL = $scope.data('voiceover-file-name');

                // Unload tracks
                if (CapabilitiesExperience.globals.backgroundTrackInitiated === true) {

                    CapabilitiesExperience.globals.voiceoverMP3.unload();
                    CapabilitiesExperience.globals.backgroundMP3.unload();

                    // Reset track intitialized state
                    CapabilitiesExperience.globals.backgroundTrackInitiated = false;
                }

                if ($scope.hasClass('active') === true) {

                    CapabilitiesExperience.globals.voiceoverMP3.unload();
                    CapabilitiesExperience.globals.backgroundMP3.unload();

                    // Reset track intitialized state
                    CapabilitiesExperience.globals.backgroundTrackInitiated = false;

                } else if ($scope.hasClass('active') === false) {

                    // Create sound objects
                    CapabilitiesExperience.globals.backgroundMP3 = new Howl({
                        src: [backgroundTrackURL],
                        loop: true,
                        volume: 1,
                        format: ['mp3']
                    });

                    CapabilitiesExperience.globals.voiceoverMP3 = new Howl({
                        src: [voiceoverTrackURL],
                        format: ['mp3']
                    });

                    // Play tracks
                    CapabilitiesExperience.globals.backgroundMP3.play();
                    CapabilitiesExperience.globals.voiceoverMP3.play();
                    CapabilitiesExperience.globals.backgroundTrackInitiated = true;
                }
                $scope.toggleClass('active');
            });
        }
    },
    distributeFields: function () {
        var self = this;
        let
            radius = 320,
            $rotaryContainer = self.globals.rotaryNavigation.find('> ul'),
            $rotaryItems = $rotaryContainer.find('.fq-rotary-item').not('.fq-hidden'),
            rotaryContainerWidth = $rotaryContainer.width(),
            rotaryContainerHeight = $rotaryContainer.height(),
            targetAngle = Math.PI * 3.5,
            targetStep = (2 * Math.PI) / $rotaryItems.length;

        if (window.innerWidth < 768) {
            radius = 130;
        } else if (window.innerWidth < 1024) {
            radius = 220;
        } else {
            radius = 260;
        }

        // Calculate X/Y coordinates
        $rotaryItems.each(function () {
            let
                x = Math.round(rotaryContainerWidth / 2 + radius * Math.cos(targetAngle) - $(this).width() / 2),
                y = Math.round(rotaryContainerHeight / 2 + radius * Math.sin(targetAngle) - $(this).height() / 2),
                $scope = $(this);

            // Assign coordinates
            $scope
                .attr('data-x-position', x)
                .attr('data-y-position', y);

            // Attach animations to each elements
            gsap.to($scope, .1, {
                x: x,
                y: y,
                onComplete: function () {
                    gsap.to($scope, .5, {
                        opacity: 1
                    });
                }
            });
            targetAngle += targetStep;
        });
    },
    revealRotary: function () {
        var self = this;
        const $slides = self.globals.capabilitiesSlides,
            $rotaryNavigation = self.globals.rotaryNavigation,
            $experienceContainer = $(self.element);

        if ($slides.length > 0) {
            $slides.on('click', '.fq-back-to-rotary', function () {
                // Destroy voiceover track
                if (self.globals.voiceoverMP3 !== null && self.globals.backgroundMP3 !== null) {
                    self.globals.voiceoverMP3.unload();
                    self.globals.backgroundMP3.unload();
                }

                $slides.removeClass('fq-reveal-slide');
                $rotaryNavigation.find('.fq-hidden').removeClass('fq-hidden');

                gsap.to($slides, .3, {
                    css: {
                        opacity: 0,
                        'display': 'none'
                    },
                    onComplete: function () {
                        $experienceContainer.removeClass('fq-slides-opened');

                        gsap.to($rotaryNavigation, .3, {
                            css: {
                                opacity: 1,
                                'display': 'block'
                            },
                            onComplete: function () {
                                self.distributeFields();
                                // Ensure we scroll to the top once the rotary navigation is revealed
                                gsap.to(window, .3, {
                                    scrollTo: 0
                                });
                            }
                        });
                    }
                });
            });
        }
    },
});