import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Ratings", {
    element: `.m-star-rating`,
    allowedDecimals: 1,
    init: function () {
        var self = this;
        if (isIE()) {
            var numberOfStars = 0, decimalValue = 0;
            if (!!$(self.element).data('rating')) {
                var ratings = $(self.element).data('rating').toString();
                var splited = ratings.split('.');
                numberOfStars = Number(splited[0]);
                decimalValue = Number(splited[1] != undefined ? splitted[1].slice(0, self.allowedDecimals) : "0");
            }
            
            for (var i = 0; i < numberOfStars; i++) {
                $(self.element).find('svg .active rect:eq(' + i + ')').attr('width', 100);
            }
            $(self.element).find('svg .active rect:eq(' + numberOfStars + ')').attr('width', (decimalValue * 10));
        }
    }
}); 