import { Component } from "fq-foundation/Scripts/Component";
import "datatables";


new Component().create("DataTable", {
    element: ".table",
    init: function () {
        $(this.element).dataTable({
            order: [[0,"asc"]]
        });
    }
});