import "fq-foundation/Scripts/HashLinkSmoothScroll.js";
import "./ResponsiveImage.js";
import "./Video.js";
import "./Slider.js";
import "./ProductFinder.js";
import "./Tabs.js";
import "./Modal.js";
import "./Ratings.js";
import "./Reviews.js";
import "./Search.js";
import "./header.js";
import "./hero.js";
import "./Animations.js";
import "./Capabilities.js";
import "./Locations.js";
import "./DataTable.js";
window.$ = window.jquery = $;