import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Hero", {
    element: ".f-hero",
    init: function () {
        $(this.element).on("click", function () {
            if ($(this).find(".f-video--overlay video").length > 0) {
                var v = $(this).find(".f-video--overlay video").get(0);
                // $(v).on("paused", function () {
                //     alert("hi")
                    
                // });
                if (!v.paused) {
                    $(this).css({
                        zIndex: 101
                    });
                } else {
                    $(this).css({
                        zIndex: 1
                    });
                }
            }
        });
    }
});