import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Maps", {
    element: ".f-teaser .f-location",
    GoogleMapId: 'm-google-map',
    mapZoomLevel: 15,
    MarkerIcon: {
        url: "/-/media/Project/Corporate/images/marker.png"
    },
    key: "AIzaSyDgh0W-G0BgraSU1mBOEU7Z7zN2yhxpGTw",
    mapStyles: [{
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#ff0000"
        }]
    }, {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#4190b7"
        }]
    }, {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [{
            "color": "#f2f2f2"
        }]
    }, {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#d2dee4"
        }]
    }, {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [{
            "visibility": "off"
        }]
    }, {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#4190b7"
        }]
    }, {
        "featureType": "road",
        "elementType": "all",
        "stylers": [{
            "saturation": -100
        }, {
            "lightness": 45
        }]
    }, {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#ececec"
        }]
    }, {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [{
            "color": "#4190b7"
        }]
    }, {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [{
            "visibility": "simplified"
        }]
    }, {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [{
            "visibility": "off"
        }]
    }, {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [{
            "visibility": "off"
        }]
    }, {
        "featureType": "water",
        "elementType": "all",
        "stylers": [{
            "color": "#46bcec"
        }, {
            "visibility": "on"
        }]
    }, {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [{
            "color": "#98c0d5"
        }]
    }],
    loadScript: function (url, callback) {
        var script = document.createElement("script")
        script.type = "text/javascript";
        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback("Success");
                }
            };
        } else {
            script.onload = function () {
                callback("Success");
            };
        }
        script.onerror = function () {
            callback("Error")
        }
        script.src = url;
        document.body.appendChild(script);
    },
    mapIds: [],
    loadMapsScript: function (callback) {
        var self = this;
        if ($("script[src=\"https://maps.googleapis.com/maps/api/js?key=" + self.key + "&libraries=&v=weekly\"]").length == 0) {
            self.loadScript("https://maps.googleapis.com/maps/api/js?key=" + self.key + "&libraries=&v=weekly", callback);
        } else {
            callback("Success");
        }
    },
    initMap: function (mapWrapper, data, country) {
        var self = this;
        var id = self.GoogleMapId + "__" + Date.now();
        self.mapIds.push(id);
        $(mapWrapper).attr("id", id);
        self.initMarker(data.Coordinates.Lat, data.Coordinates.Long, mapWrapper);
    },
    init: function () {
        var self = this;
        self.loadMapsScript(function (status) {
            $(self.element).each(function () {
                var el = $(this);
                var data = $(el).data("regions");
                self.initMap($(el).get(0), data, $(this));
            });
        });
    },
    initMarker: function (lat, lng, el) {
        var self = this;
        var position = new google.maps.LatLng(lat, lng);
        var mapOptions = {
            zoom: self.mapZoomLevel,
            center: position,
            zoomControl: false,
            disableDoubleClickZoom: true,
            mapTypeControl: false,
            scaleControl: false,
            scrollwheel: true,
            panControl: true,
            streetViewControl: false,
            draggable: true,
            overviewMapControl: true,
            overviewMapControlOptions: {
                opened: false,
            },
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: self.mapStyles
        };
        var map = new google.maps.Map(document.getElementById($(el).attr("id")), mapOptions);
        self.initMapMarkers(map, el, position);
    },
    initMapMarkers: function (map, el, position) {
        var self = this;
        var marker = new google.maps.Marker({
            icon: self.MarkerIcon,
            position: position,
            map: map,
        });
        map.setCenter(marker.getPosition());
    }
});