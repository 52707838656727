import { Component } from "fq-foundation/Scripts/Component";

new Component().create("ProductFilter", {
    element: ".f-product-finder",
    submit: ".f-product-finder .a-button",
    dropdowns: ".finder-wrapper select",
    init: function () {
        var self = this;
        self.bindForm();
    },
    bindForm: function () {
        var self = this;
        var gender = productType = leakageLevel = '';
        $(self.submit).on('click', function (e) {
            // Validate Form having atleast 1 dropdown selected
            var count = 0;
            $(self.element).find(self.dropdowns).each(function () {
                if($(this).find('option:selected').attr('value') != undefined && $(this).find('option:selected').attr('value') != "") {
                    count++;
                }
            });
            if (count < 3) {
                e.preventDefault();
                return false;
            }
        });
        $(self.dropdowns).on('change', function () {
            var typeOfSelection = $(this).attr("name");
            if(typeOfSelection == 'gender') {
                gender = this.value;
            }
            if(typeOfSelection == 'productType') {
                productType = this.value;
            }
            if(typeOfSelection == 'leakageLevel') {
                leakageLevel = this.value;
            }
            if (gender != '' && productType != '' && leakageLevel != '') {
                $(self.submit).attr("href", "/products/" + gender + "?producttype=" + productType + "&leakagetype=" + leakageLevel);
            } else {
                $(self.submit).attr("href", "javascript:void(0)");
            }
        });
    }
});